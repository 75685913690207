body,button{
    margin: 0;
    background-color: #E5E5E5;
    font-family: 'Poppins';
    font-style: normal;
    
}
#root> *:not(header){
    padding:0 15%;
}
header{
    width: 100%;
    background-color: rgba(73, 73, 187, 0.76);
    color: white;
}
h1{
    padding: 15px 0;
    margin-left: 15%;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
}
h2{
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
}
.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.container button{
    padding: 13px 21px 13px 14px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: white;
    background: #16ABF8;
    border: none;
    border-radius: 45px;
}
.card-list{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 234px;
    min-width: 150px;
    flex: 0 1 calc(20% - 17px);
    background-color: white;
    padding: 25px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    
}
.card div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #888888;
}
.card div img{
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.detail-container .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vw;
    width: 100%;
}
.detail-container h3{
    color: blue;
    cursor: pointer;
}

.head button{
    padding: 13px 21px 13px 14px;
    height: fit-content;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: white;
    background: #16ABF8;
    border: none;
    border-radius: 45px;
}
.head > div{
    display: flex;
    align-items: center;
    gap: 20px;
}
.head div:first-child input{
        outline: 0;
        border-width: 0 0 2px;
        background-color: rgba(0, 0, 0, 0);
        border-color: black
}
.head div input[type="text"]
{
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
}
.Form{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: grey;
}
.form-card{
    margin: auto;
    width: 60%;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
}
.form-card *{
    margin: 10px;
}
.form-body input{
    width: 80%;
}